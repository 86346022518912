import { Typography } from '@mui/material';
import React from 'react';

function Slogan(props) {
  return (
    <Typography variant="caption" {...props}>
      {'Above & Beyond Investing'}
    </Typography>
  );
}

export default Slogan;
